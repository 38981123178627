@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin center($xy:xy) {
  @if $xy == xy {
    @include position(absolute, 50% auto auto 50%);
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    @include position(absolute, auto auto auto 50%);
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    @include position(absolute, 50% auto auto auto);
    @include transform(translateY(-50%));
  }
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin container() {
  @include outer-container();
  @include pad(0 $default-padding * 1.2);
}
