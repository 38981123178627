#swipebox-overlay {
  background: rgba(black, 0.85);
}

#swipebox-top-bar,
#swipebox-bottom-bar {
  background: transparent;
}

#swipebox-slider .slide-loading {
  background: url("../../images/loader.gif") no-repeat center center;
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
  background-image: url("../../images/icons.png");
}
