@import "~bourbon/app/assets/stylesheets/bourbon";
@import "~bourbon-neat/app/assets/stylesheets/neat";
@import "~@fancyapps/fancybox/dist/jquery.fancybox";
@import "partials/mixins";
@import "partials/overrides";
@import "~normalizecss/normalize";

@font-face {
  font-family: 'Aller';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/aller_rg-webfont.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/aller_rg-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/aller_rg-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/aller_rg-webfont.woff') format('woff'), /* Modern Browsers */
  url('./fonts/aller_rg-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/aller_rg-webfont.svg#Allerta') format('svg'); /* Legacy iOS */
}


$fg-column: $column;
$fg-gutter: $gutter;

$desktop: new-breakpoint(min-width 1024px, 12);
$tablet: new-breakpoint(max-width 940px, 9);
$mobile: new-breakpoint(max-width 700px, 9);

$default-padding: $em-base;
$border-box-sizing: true;

$grey: #b4b4b4;
$lightgrey: lighten($grey, 20);
$darkgrey: lighten(black, 10%);
$red: #d62e48;

$aller: "Aller", $helvetica;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $aller;
  line-height: 1.75em;
  background: white;
  color: $darkgrey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  text-transform: uppercase;
}

h1 {
  margin: 0 0 0.5em;
  line-height: 1.1em;
}

h2 {
  margin: 0 0 0.25em;
}

h3 {
  margin: 0;
}

p {
  margin: 0 0 1em;
}

a {
  color: inherit;

  &.button {
    text-decoration: none;
    color: white;
    background: $red;
    padding: 0.25em 0.5em;

    &:hover {
      background: $grey;
    }

  }

  &.arrow {
    visibility: hidden;
    opacity: 0;
    @include hide-text();
    @include size(44px 24px);
    display: block;
    background: url("../images/arrow.svg") no-repeat center center;
    background-size: cover;
    position: fixed;
    bottom: 1%;
    right: 1%;
    transition: opacity 0.25s ease-in-out;
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

}

figure {
  margin: 0;
}

.offcanvas {
  @include size(100%);
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 10000;
  visibility: hidden;
  overflow-y: scroll;

  .navexpanded & {
    visibility: visible;
  }

}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: white;
  border-bottom: 1px solid $lightgrey;
}

footer {
  margin-top: $default-padding * 4;
  background: $lightgrey;
  color: $grey;

  &.nomargin {
    margin-top: 0;
  }
  
  .inner {
    @include container();

    .logo,
    .meta {
      @include span-columns(6);
      padding: $default-padding * 2 0;

      @include media($mobile) {
        @include fill-parent();
        padding: $default-padding 0;
      }

    }

    .meta {
      text-align: right;

      @include media($mobile) {
        display: none;
      }

      a {
        color: $grey;
        text-decoration: none;
        display: inline-block;
      }

    }

    img {
      width: 23.4375vw;
      max-width: 240px;
      display: block;

      @include media($mobile) {
        width: 200px;
        margin: 0 auto;
      }

    }

  }

}

.bar {
  @include container();
  margin-top: 80px;

  @include media($mobile) {
    margin-top: 0;
  }


  .logo {
    display: block;
    float: left;
    @include size(80px);

    @include media($mobile) {
      @include size(40px);
    }

  }

  .wordmark {
    display: block;
    float: right;
    margin-top: 3.75vw;
    width: 23.4375vw;
    max-width: 240px;

    @include media($desktop) {
      margin-top: 32px;
    }

    @include media($mobile) {
      display: none;
    }

  }

  .mainnav ul {
    list-style: none;
    margin-top: 3.9vw;
    padding: 0;
    float: left;

    @include media($desktop) {
      margin-top: 35px;
    }

    @include media($mobile) {
      display: none;
    }

    li {
      float: left;

      a {
        display: inline-block;
        padding: 0 $default-padding;
        text-decoration: none;
        text-transform: uppercase;
        color: $grey;
        border-left: 2px solid $grey;

        @include media($mobile) {
          font-size: 80%;
        }

        &:hover, &.active {
          color: $red;
        }

      }

      &.first a {
        border-left: none;
      }

    }

  }

}

.navtoggle {
  width: 28px;
  height: 28px;
  margin-top: 6px;
  display: none;
  @include hide-text();
  background: url("../images/burger.svg") no-repeat 0 0;
  float: right;
  transition: 0.25s ease-in-out;

  @include media($mobile) {
    display: block;
  }

  .navexpanded & {
    transform: rotate(90deg);
  }

}

.mobilenav ul {
  list-style: none;
  margin: 0;
  padding: 0 0 40px;
  background: rgba(black, 0.85);
  position: absolute;
  z-index: 100;
  top: -100vh;
  left: 0;
  width: 100%;
  transition: all 0.25s ease-in-out;

  ul {
    position: relative;
    top: auto;
    left: auto;
    background: transparent;
  }

  a {
    color: $lightgrey;
    font-size: 1em;
    line-height: 3em;
    text-decoration: none;
    text-transform: uppercase;
    @include pad(0 $default-padding);
    display: block;
    border-bottom: 1px solid rgba($lightgrey, 0.5);

    &:hover {
      color: $red;
    }

  }

  ul a {
    @include pad(0 $default-padding * 2);
  }

}

section.margin {
  transition: margin-top 0.25s ease-in-out;

  &.first {
    margin-top: 160px;

    @include media($mobile) {
      margin-top: 40px;
    }

  }

  &.bottom {
    margin-bottom: $default-padding * 6;

    @include media($mobile) {
      margin-bottom: $default-padding * 3;
    }

  }

  &.top {
    margin-top: $default-padding * 6;

    @include media($mobile) {
      margin-top: $default-padding * 3;
    }

  }

}

.intro {
  @include size(100%);
  padding-top: 85vh;

  @include media($mobile) {
    padding-top: 75vh;
  }

}

.header {
  @include container();

  &.centered {
    text-align: center;
  }

  h1, h2 {
    text-transform: uppercase;
  }

}

.boxes {
  @include container();
  @include media($mobile) {
    width: 100%;
    padding: 0;
  }

  .box {
    @include span-columns(4);
    background: rgba($darkgrey, 0.8);
    color: white;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 4vh 0;
    font-size: 1.4em;

    &:hover, &.active {
      background: $red;
    }

    @include media($mobile) {
      width: 100%;
      padding: 2vh 0;
      font-size: 1.2em;
      border-bottom: 2px solid white;

      &.last {
        border-bottom: none;
      }

    }

  }

}

.headimage {
  width: 100%;
  max-width: 80em;
  margin: 0 auto;
  height: 40vh;
  max-height: 600px;
  background-size: cover;
  background-position: center center;

  @include media($mobile) {
    height: 25vh;
  }

}

.teasers {
  @include container();

  @include media($mobile) {
    margin-bottom: $default-padding * 2;
  }

  a {
    @include span-columns(4);
    @include omega(3n);

    margin-bottom: $default-padding * 1.5;
    position: relative;
    text-decoration: none;

    @include media($mobile) {
      @include fill-parent();
      @include omega-reset(3n);
      margin-bottom: $default-padding * 4;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    span {
      @include size(100%);
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      opacity: 0;
      background: rgba($red, 0.8);
      padding: 5%;
      color: white;
      font-size: 1.1em;
      line-height: 1.2em;
      text-transform: uppercase;
      transition: opacity 0.25s ease-in-out;

      .icon {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(white, 0.5);
        font-size: 1.6em;
        padding: 0 0.3em;
      }

      @include media($mobile) {
        @include size(auto);
        background: transparent;
        color: black;
        opacity: 1;
        padding: 0;
        top: 101%;
      }

    }

    &:hover span {
      opacity: 1;
    }

  }

}

.vita {
  @include container();
  margin-top: $default-padding * 2;

  .picture {
    @include span-columns(4);

    @include media($mobile) {
      @include span-columns(9);
      margin-bottom: $default-padding;
    }

  }

  table {
    @include span-columns(8);

    @include media($mobile) {
      @include span-columns(9);
      margin-bottom: $default-padding;
    }

    th {
      width: percentage(1/5);
      text-align: left;
      vertical-align: top;
      font-weight: normal;
      border-bottom: 1px solid white;
      padding: 0.25em 0;
    }

    td {
      width: percentage(4/5);
      border-bottom: 1px solid white;
      padding: 0.25em 0;
    }

    tr:last-child td {
      padding-top: $default-padding;
    }

    .last, .name {

      th, td {
        border-bottom: none;
      }

    }

    .name {
      font-size: 1.4em;
      text-transform: uppercase;
    }

  }

}

.lead {
  @include container();
  margin-bottom: $default-padding * 2;

  .title {
    @include span-columns(4);

    @include media($tablet) {
      @include fill-parent();
    }

  }

  .content {
    @include span-columns(8);
    font-size: 1.1em;

    @include media($tablet) {
      @include fill-parent();
    }

  }

}

.caption {
  font-size: 1.4em;
  color: $grey;
}

table.data {
  width: 100%;
  color: $grey;

  tr {
    border-bottom: 1px solid $lightgrey;

    &.last {
      border: none;
    }

  }

  th {
    text-align: left;
    width: 33.3333%;
    text-transform: uppercase;


    @include media($mobile) {
      width: 66.6666%;
    }

  }

  td {
    width: 66.6666%;


    @include media($mobile) {
      width: 33.3333%;
    }

  }

  th, td {
    vertical-align: top;
    padding: 0.25em 0;
  }

}

.gallery {
  @include container();

  a {
    @include span-columns(2);
    @include omega(6n);
    margin-bottom: $default-padding * 1.5;

    @include media($mobile) {
      @include span-columns(3);
      @include omega-reset(6n);
      @include omega(3n);
    }

    img {
      max-width: 100%;
      display: block;
    }

    &.hidden {
      display: none;
    }

  }

}

.map-wrapper {
  @include container();
}

.map {
  width: 100%;
  height: 40vh;
  max-height: 600px;
}
